import React, {useEffect, useState} from 'react'
import NewMeme from '../images/newmeme.png'

function Meme() {
  
  const [meme, setMeme] = useState({
    topText: "",
    bottomText: "",
    randomImage: "https://i.imgflip.com/8k0sa.jpg"
  })

  const [allMemeImages, setAllMemeImages] = useState([])

  useEffect(() => {
    async function fetchMemes() { 
      const res = await fetch("https://api.imgflip.com/get_memes")
      const data = await res.json()
      setAllMemeImages(data.data.memes)
    }
    fetchMemes()

    /*return () => {
     clean up space
    }*/

  }, [])


  function handleChange(event){
    const {name, value} = event.target
    setMeme(prevMeme => ({
        ...prevMeme,
        [name] : value
    }))
  }

  function getMemeImage() {
    const randomNumber = Math.floor(Math.random() * allMemeImages.length)
    setMeme(prevMemeData => ({
        ...prevMemeData, 
        randomImage: allMemeImages[randomNumber].url
    }))
} 

  return (
    <main>
    <div className='meme'>
        <div className='meme--form'>
            <div className='meme--inputs'>
                <input 
                  placeholder='Add upper text' 
                  type="text" 
                  name="topText" 
                  className='meme--uppertext' 
                  value={meme.topText} 
                  onChange={handleChange}>
                </input>
                <input 
                  placeholder='Add lower text' 
                  type="text" 
                  name="bottomText" 
                  className='meme--lowertext' 
                  value={meme.bottomText} 
                  onChange={handleChange}>
                </input>
            </div>
            <button 
              className='meme--newimage' onClick={getMemeImage}>Get a new meme image <img src={NewMeme}></img>
            </button>
        </div>

        <div className='meme--image'>
          <img src={meme.randomImage}></img>
          <h2 className="meme--text top">{meme.topText}</h2>
          <h2 className="meme--text bottom">{meme.bottomText}</h2>
        </div>
    </div>    
    </main>    
  )
}

export default Meme
