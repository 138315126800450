import './App.css';
import Header from './components/Header'
import Meme from './components/Meme'

function App() {
  return (
    <>
      <Header/>
      <Meme/>
    </>
  );
}

export default App;
