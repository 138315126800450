import React from 'react'
import Troll from '../images/trollface.png'

export default function Header() {
  return (
    <header className='header'>
            <img src={Troll} className='header--logo'></img>
            <h2 className='header--title'>Meme Maker</h2>

    </header>
  )
}
